








































































































































import Vue from "vue";
import PersonaService from "@/api/PersonaService";
import DatePicker from "@/components/shared/DatePicker.vue";
export default Vue.extend({
  components: {
    DatePicker
  },
  props: ["dialog", "dato"],
  data() {
    return {
      showPassword: false,
      editedIndex: -1,
      editedItem: {
        nombre: "",
        apellido: ""
      },
      identidadItems: [
        { text: "Cédula", value: "CED" },
        { text: "RUC", value: "RUC" },
        { text: "Pasaporte", value: "PAS" }
      ],
      ///sexoitems: ["Masculino", "Femenino"],
      sexoItems: [
        { text: "Masculino", value: "M" },
        { text: "Femenino", value: "F" }
      ],
      statusItems: [
        { text: "Activo", value: true },
        { text: "Inactivo", value: false }
      ],
      roleItems: [
        { text: "Cliente", value: "0" },
        { text: "Administrador", value: "1" },
        { text: "Editor", value: "2" }
      ]
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      PersonaService.update(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Persona",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Persona",
            text: "No se pudo guardar"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
