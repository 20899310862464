



















































import Vue from "vue";
export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: "Seleccione Fecha"
    },
    min: {
      type: String,
      required: false,
      default: undefined
    },
    max: {
      type: String,
      required: false,
      default: undefined
    }
  },
  created() {
    this.fecha = this.value;
  },
  data() {
    return {
      modalF1: false,
      fecha: "",
      // old
      time: "00:00",
      menu2: false,
      modal2: false,
      respuesta: ""
    };
  },

  methods: {},
  watch: {
    fecha: function(newVal) {
      this.respuesta = newVal;
    },
    respuesta: function(newVal) {
      this.$emit("input", newVal);
    },
    value: function(newVal) {
      this.fecha = newVal;
    }
  }
});
