
























































import PersonaUpdate from "@/components/admin/persona/PersonaUpdate.vue";
import Vue from "vue";
import PersonaService, {
  PersonaDto,
  PersonaUpdateDto
} from "@/api/PersonaService";

export default Vue.extend({
  components: {
    PersonaUpdate
  },
  metaInfo: {
    title: "Distancias"
  },
  data() {
    return {
      search: "",
      dialog: false,
      dato: {
        nombre: ""
      } as PersonaUpdateDto,
      headers: [
        { text: "#", value: "id" },
        { text: "Id", value: "identificacion" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "Sexo", value: "sexo" },
        { text: "Edad", value: "edad" },
        { text: "Discapacitado", value: "discapacitado" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as PersonaDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        apellido: ""
      };
      this.dato = newDto;
    },
    editItem(item: PersonaUpdateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      PersonaService.getAll()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Personas",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
