import httpClient from "./http-client";

const ENDPOINT = "/persona";

export interface PersonaDto {
  id?: number;
  nombre: string;
  apellido: string;
}

export interface PersonaUpdateDto {
  id?: number;
  nombre: string;
  carnetConadis?: string;
}

class PersonaService {
  getAll(): Promise<PersonaDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  update(updateDto: PersonaUpdateDto) {
    /*
    const { carnetConadis } = updateDto;
    if (!carnetConadis) {
      delete updateDto["carnetConadis"];
    }
    */
    return httpClient.put(ENDPOINT + "/" + updateDto.id, updateDto);
  }
}
export default new PersonaService();
